import { Component, Vue, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import { Stats } from '@/models/Stats';
import ErrorHandler from '@/support/ErrorHandler';
import { identifyHostname } from '@/support/Client';
import { Token } from '@/support/Token';
import { ReportType } from '@/models/ReportType';
import { Organization } from '@/models/Organization';
import { TableVisibility } from '@/components/data-table/DataTable';

@Component<LicenseStats>({

})
export default class LicenseStats extends Vue {
  // #region Props

  public $pageTitle = 'Licentie overzicht';

  public oAuthServer: string = identifyHostname() || '';

  protected weekStats: Stats[] = [];

  protected isLoading = true;

  protected organizations: Organization[] = [];

  protected selectedOrganization = '';

  protected activeTab = 'cumulatief';

  protected selectedYear = '2019';

  protected selectedReportType = '';

  protected reportTypes: ReportType[] = [];

  protected years: Year[] = [
    {
      name: '2018',
      value: '2018',
    },
    {
      name: '2019',
      value: '2019',
    },
  ]

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: false,
    search: false,
    pagination: false,
  }

  protected mapColors: any = {
    '10be': 'rgba(133, 34, 34, 0.8)',
    nivre: 'rgba(249, 141, 53, 0.8)',
    dog: 'rgba(249, 189, 53, 0.8)',
    ced: 'rgba(85, 26, 139, 0.8)',
    expert: 'rgba(0, 153, 0, 0.8)',
    tcmg: 'rgba(0, 153, 204, 0.8)',
    msr: 'rgba(204, 0, 102, 0.8)',
  };

  // #endregion

  // #region Lifecycle hooks / Init

  protected mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.getOrganizations();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Licentie overzicht' },
        ],
      });
  }

  // #endregion

  // #region Helpers

  protected getAxiosClient() {
    const headers = {};
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }
    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  // #endregion

  // #region API requests

  protected getReportTypes() {
    new ReportType()
      .all()
      .then((reportTypes: ReportType[]) => {
        this.reportTypes = reportTypes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .include('totals')
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters

  protected get totalActiveUsers(): number {
    return this.organizations?.reduce((accumulator, organization) => accumulator + (organization.totals?.active_users || 0), 0) || 0;
  }

  protected get totalInactiveUsers(): number {
    return this.organizations?.reduce((accumulator, organization) => accumulator + (organization.totals?.inactive_users || 0), 0) || 0;
  }

  // #endregion

  // #region @Watchers

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  // #endregion
}

// #region Enums, Interfaces & Types

interface Year {
  [key: string]: string;
}

// #endregion
