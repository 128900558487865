import { render, staticRenderFns } from "./LicenseStats.vue?vue&type=template&id=01be4a45&scoped=true&"
import script from "@/views/LicenseStats/LicenseStats.ts?vue&type=script&lang=ts&"
export * from "@/views/LicenseStats/LicenseStats.ts?vue&type=script&lang=ts&"
import style0 from "@/views/LicenseStats/license-stats.scss?vue&type=style&index=0&id=01be4a45&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01be4a45",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01be4a45')) {
      api.createRecord('01be4a45', component.options)
    } else {
      api.reload('01be4a45', component.options)
    }
    module.hot.accept("./LicenseStats.vue?vue&type=template&id=01be4a45&scoped=true&", function () {
      api.rerender('01be4a45', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LicenseStats/LicenseStats.vue"
export default component.exports