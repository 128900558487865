var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.isBETA
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Licentie overzicht")
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "elementPanel elementPanel--spaced fill-height"
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c("canvas", {
                            attrs: {
                              id: "licenseChart",
                              height: "200",
                              width: "600"
                            }
                          })
                        ]),
                        _vm.organizations.length
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "item__list",
                                    attrs: { wrap: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "label",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Organizatie")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Actieve gebruikers")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Inactieve gebruikers")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.organizations, function(
                                      organization,
                                      index
                                    ) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: index,
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(organization.name)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        organization.totals
                                                          .active_users
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        organization.totals
                                                          .inactive_users
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "list__item list__item--bold",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "", wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [_c("span", [_vm._v("Totalen")])]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.totalActiveUsers)
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.totalInactiveUsers
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "old", attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c("h2", { staticClass: "elementSubTitle" }, [
            _vm._v("Licentie overzicht")
          ]),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", lg6: "" } }, [
                !_vm.$store.state.isBETA
                  ? _c("canvas", {
                      attrs: { id: "licenseChart", height: "200", width: "600" }
                    })
                  : _vm._e()
              ]),
              _c("v-flex", { attrs: { xs12: "", lg6: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel" },
                  [
                    _c("h3", [_vm._v("Totalen")]),
                    _vm.organizations.length
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "table__list",
                                attrs: { wrap: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "label",
                                        attrs: { wrap: "" }
                                      },
                                      [
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [_vm._v("Organizatie")])
                                        ]),
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [
                                            _vm._v("Actieve gebruikers")
                                          ])
                                        ]),
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [
                                            _vm._v("Inactieve gebruikers")
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  [
                                    _vm._l(_vm.organizations, function(
                                      organization,
                                      index
                                    ) {
                                      return _c(
                                        "v-layout",
                                        {
                                          key: index,
                                          staticClass: "list__item",
                                          attrs: { row: "", wrap: "" }
                                        },
                                        [
                                          _c("v-flex", { attrs: { xs4: "" } }, [
                                            _c("span", [
                                              _vm._v(_vm._s(organization.name))
                                            ])
                                          ]),
                                          _c("v-flex", { attrs: { xs4: "" } }, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  organization.totals
                                                    .active_users
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("v-flex", { attrs: { xs4: "" } }, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  organization.totals
                                                    .inactive_users
                                                )
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "list__item list__item--totals",
                                        attrs: { row: "", wrap: "" }
                                      },
                                      [
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [_vm._v("Totalen")])
                                        ]),
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.totalActiveUsers))
                                          ])
                                        ]),
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.totalInactiveUsers)
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }